import {useState, useEffect} from "react"

const defaultZoomValue = 500

function Controls(props)
{
    const [value, setValue] = useState(defaultZoomValue)
    const realSetValue = (value) => {
        value = parseInt(value)
        setValue(value)
        props.setZoom && props.setZoom(value / 1000)
    }
    useEffect(() => {
        setTimeout(() => {
            realSetValue(defaultZoomValue)
        }, 0)
    }, []);
    
    return <div className="controls">
            {props.setZoom && <input type="range" min={0} max={1000} value={value} onChange={(event) => realSetValue(event.target.value)}/>}
            {props.triggerJob && <input type="button" value={props.action} onClick={props.triggerJob}/>}
            {props.setClosed && <div className="close" onClick={props.setClosed} >×</div>}
        </div>
}

export default Controls;


