import {useState} from "react"

import Geometry from "./Geometry.js"
import Wait from "./Wait.js"


function ImageViewer(props)
{
    if (props.image === null) {
        return <div className="image-editor"><Wait /></div>
    }
    const containerParams = Geometry.calcContainerParams(props.size)
    const containerSize = Geometry.calcContainerSize(props.size, props.image, {x: 1, y: 1}, containerParams)
    return <div className="image-editor">
        <div className="image-editor-inner">
            <div className="image-editor-container" style={{
                    width: containerSize.x + "px",
                    height: containerSize.y + "px",
                    marginTop: containerParams.borderSize + "px",
                    marginBottom: containerParams.borderSize + "px"
                }}
            >
                <img className="image" src={props.image.src} />
            </div>
        </div>
    </div>
}

export default ImageViewer;


