import {useState, useRef} from "react"
import Geometry from "./Geometry.js"

function onMouseDown(event, commitMouse)
{
    if (event.buttons & 1) {
        var state = {firstX: event.pageX, firstY: event.pageY, currentX: event.pageX, currentY: event.pageY, last: false}
        var removeEventListeners = () => {}
        const mouseMoveHandler = (event) => {
            if (event.buttons & 1) {
                state.currentX = event.pageX
                state.currentY = event.pageY
            } else {
                state.last = true
                removeEventListeners()
            }
            commitMouse(state)
        }
        const mouseUpHandler = (event) => {
            if (!(event.buttons & 1)) {
                state.last = true
                removeEventListeners()
                commitMouse(state)
                event.preventDefault()
                return false
            }
        }

        removeEventListeners = () => {
            document.removeEventListener("mousemove", mouseMoveHandler)
            document.removeEventListener("mouseup", mouseUpHandler)
        }
        document.addEventListener("mousemove", mouseMoveHandler)
        document.addEventListener("mouseup", mouseUpHandler)

        commitMouse(state)
        event.preventDefault()
        return false
    }
}

function touchEvent(event, setTranslation, translationLimits, zoomRatio, ref)
{
    if (event.touches.length === 1) {
        const rect = ref.current.getBoundingClientRect();
        const x = Math.round((event.touches[0].clientX - rect.left) / zoomRatio);
        const y = Math.round((event.touches[0].clientY - rect.top) / zoomRatio);
        setTranslation(Geometry.pickTranslation(translationLimits, {x, y}))
    }
}

function ImageEditor(props)
{
    const ref = useRef(null);
    const [previousTranslation, setTranslation] = useState({x: Geometry.intdiv(props.image.x, 2), y: Geometry.intdiv(props.image.y, 2)})
    const [mouseMove, setMouseMove] = useState({x: 0, y: 0})

    const containerParams = Geometry.calcContainerParams(props.size)
    const containerSize = Geometry.calcContainerSize(props.size, props.image, props.stamp, containerParams)
    const stampZoomLimits = Geometry.calcStampZoomLimits(props.image, props.stamp)
    const stampZoom = Geometry.pickStampZoom(stampZoomLimits, props.zoom)
    const viewZoom = Geometry.zoomToView(stampZoom, containerSize)
    const translationLimits = Geometry.calcTranslationLimits(props.image, props.stamp, stampZoom)
    var translation = Geometry.pickTranslation(translationLimits, previousTranslation)
    if (translation.x !== previousTranslation.x || translation.y !== previousTranslation.y) {
        setTimeout(() => setTranslation(translation), 0)
    }
    translation = Geometry.pickTranslation(translationLimits, {x: translation.x + mouseMove.x, y: translation.y + mouseMove.y})
    const viewTranslation = Geometry.translationToView(translation, containerSize)
    
    const commitMouse = (state) => {
        const x = Math.floor((state.currentX - state.firstX) / containerSize.zoomRatio)
        const y = Math.floor((state.currentY - state.firstY) / containerSize.zoomRatio)
        if (state.last) {
            translation = setTranslation(Geometry.pickTranslation(translationLimits, {x: translation.x + x, y: translation.y + y}))
            setMouseMove({x: 0, y: 0})
        } else {
            setMouseMove({x, y})
        }
    }
    
    const finalSizes = Geometry.calcFinalSizes(props.image, props.stamp, stampZoom)
    const finalOffset = Geometry.calcFinalOffset(translation, translationLimits, finalSizes.resizeRatio)

    props.job.image = props.image.src
    props.job.stamp = props.stamp.src
    props.job.imageOrigSize = {x: props.image.x, y: props.image.y}
    props.job.stampOrigSize = {x: props.stamp.x, y: props.stamp.y}
    props.job.imageFinalSize = finalSizes.image
    props.job.stampFinalSize = finalSizes.stamp
    props.job.stampFinalOffset = finalOffset
    props.job.originalFilename = props.image.filename

    const mouseDownHandler = (event) => onMouseDown(event, commitMouse)

    return <div className="image-editor">
        <div className="image-editor-inner">
            <div ref={ref} className="image-editor-container" style={{
                    width: containerSize.x + "px",
                    height: containerSize.y + "px",
                    marginTop: containerParams.borderSize + "px",
                    marginBottom: containerParams.borderSize + "px"
                }}
              onMouseDown={(event) => {event.preventDefault(); return false}}
              onTouchStart={(event) => touchEvent(event, setTranslation, translationLimits, containerSize.zoomRatio, ref)}
            >
                <img className="image" alt="uploaded image" src={props.image.src} />
                <img className="stamp" alt="stamp" src={props.stamp.src} style={{
                        width: viewZoom.x + "px",
                        height: viewZoom.y + "px",
                        left: viewTranslation.x,
                        top: viewTranslation.y
                    }}
                    onMouseDown={mouseDownHandler}
                />
            </div>
        </div>
    </div>
}

export default ImageEditor;


