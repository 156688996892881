import {useState, useRef, useLayoutEffect, useEffect} from "react"

import SelectStamp from "./SelectStamp.js"
import SelectImage from "./SelectImage.js"
import ImageEditor from "./ImageEditor.js"
import ImageViewer from "./ImageViewer.js"
import Controls from "./Controls.js"
import invokeJob from "./Job.js"

async function downloadImage(imageSrc, nameOfDownload)
{
  const response = await fetch(imageSrc);

  const blobImage = await response.blob();

  const href = URL.createObjectURL(blobImage);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = nameOfDownload;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

function doResolveSizes(img, setter)
{
    var additionalData = {}
    if (img instanceof Object) {
        additionalData = {...img}
        img = additionalData.src
        delete additionalData.src
    }
    if (img !== null) {
        var image = new Image()
        image.onload = function () {
            setter({...additionalData, "src": img, "img": this, "x": this.width, "y": this.height})
        }
        image.src = img
    } else {
        setter(null)
    }
}

function resolveSizes(setter)
{
    return (img) => doResolveSizes(img, setter)
}

function Editor()
{
    const ref = useRef(null);

    const [file, setFile] = useState(null)
    const [stamp, setStamp] = useState(null)
    const [finalImage, setFinalImage] = useState(false)
    const [job, setJob] = useState({})

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [zoom, setZoom] = useState(0.5)

    function handleWindowResize() {
        setTimeout(() => {
            setWidth(ref.current.clientWidth)
            setHeight(ref.current.clientHeight)
        }, 0)
    }

    function realSetFile(data)
    {
        setFile(data)
        handleWindowResize()
    }


    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        var timeout = setTimeout(() => {
            handleWindowResize()
            timeout = null
        }, 1000)
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout)
            }
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    
    useLayoutEffect(handleWindowResize, [])

    const triggerJob = () => {
        if (job !== null) {
            setFinalImage(null)
            invokeJob(job).then(img => doResolveSizes(img, img => {setFinalImage(img); handleWindowResize()}))
        }
    }

    const triggerDownload = () => {
        if (finalImage !== false && finalImage !== null && finalImage.src !== null) {
            downloadImage(finalImage.src, finalImage.finalFilename)
        }
    }

    const setClosed = () => {
        realSetFile(null)
        setJob({})
        setFinalImage(false)
    }

    return <div className="editor">
        {(finalImage === false) && <SelectStamp setStamp={resolveSizes(setStamp)} />}
        {(stamp !== null && file !== null && finalImage === false) && <Controls setClosed={setClosed} setZoom={setZoom} triggerJob={triggerJob} action="Vytvořit obrázek"/>}
        {(stamp !== null && file !== null && finalImage !== false) && <Controls setClosed={setClosed} setZoom={null} triggerJob={(finalImage === null) ? null :triggerDownload} action="Stáhnout"/>}
        <div ref={ref} className="image-edit">
            {(stamp === null) && <h2>Prosím vyberte rámeček</h2>}
            {(stamp !== null && file === null) && <SelectImage setFile={resolveSizes(realSetFile)} />}
            {(stamp !== null && file !== null && finalImage === false) && <ImageEditor size={{x: width, y: height}} image={file} stamp={stamp} zoom={zoom} job={job}/>}
            {(stamp !== null && file !== null && finalImage !== false) && <ImageViewer size={{x: width, y: height}} image={finalImage}/>}
        </div>
    </div>
}

export default Editor;
