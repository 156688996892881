const minSize = 0.3
const globalMinZoomRatio = 0.2
const finalImageMinSize = 1024

function calcContainerParams(elementSize)
{
    return {borderSize: 20, minSize: Math.round(elementSize.x * minSize)}
}

function calcContainerSize(elementSize, imageSize, stampSize, containerParams)
{
    const ratio = imageSize.y / imageSize.x
    const x1 = imageSize.x
    const x2 = imageSize.x * (Math.max(1, elementSize.y - 2 * containerParams.borderSize) / imageSize.y)
    const x3 = Math.max(1, elementSize.x - 2 * containerParams.borderSize)
    
    const x = Math.min(x1, Math.max(containerParams.minSize, x2), x3)
    
    return {x: x, y: x * ratio, imageRatio: ratio, stampRatio: stampSize.y / stampSize.x, zoomRatio: x / imageSize.x}
}

function calcStampZoomLimits(imageSize, stampSize)
{
    const additionalZoomRatio = Math.max(imageSize.x, imageSize.y) / finalImageMinSize
    const totalMaxZoomRatio = Math.min(imageSize.x / stampSize.x, imageSize.y / stampSize.y)
    const maxZoomRatio = Math.min(totalMaxZoomRatio, Math.max(1, additionalZoomRatio))
    const minZoomRatio = Math.min(globalMinZoomRatio * totalMaxZoomRatio, maxZoomRatio)
    const xMax = Math.max(1, Math.round(stampSize.x * maxZoomRatio))
    const xMin = Math.max(1, Math.round(stampSize.x * minZoomRatio))
    return {xMin, xMax}
}

function pickStampZoom(stampZoomLimits, zoomRatio)
{
    if (zoomRatio < 0) {
        zoomRatio = 0
    } else if (zoomRatio > 1) {
        zoomRatio = 1
    }
    return Math.round(stampZoomLimits.xMin + zoomRatio * (stampZoomLimits.xMax - stampZoomLimits.xMin))
}

function zoomToView(zoom, containerSize)
{
    return {x: zoom * containerSize.zoomRatio, y: zoom * containerSize.zoomRatio * containerSize.stampRatio}
}

function intdiv(a, b)
{
    return (a - a%b)/b
}

function calcTranslationLimits(imageSize, stampSize, zoom)
{
    const zoomedStampSize = {x: zoom, y: Math.round(zoom * stampSize.y / stampSize.x)}
    const xMin = intdiv(zoomedStampSize.x, 2)
    const yMin = intdiv(zoomedStampSize.y, 2)
    const xMax = imageSize.x - zoomedStampSize.x + xMin
    const yMax = imageSize.y - zoomedStampSize.y + yMin

    return {xMin, yMin, xMax, yMax, xStamp: zoomedStampSize.x, yStamp: zoomedStampSize.y}
}

function pickTranslation(translationLimits, previousTranslation)
{
    var x = previousTranslation.x
    var y = previousTranslation.y
    if (x < translationLimits.xMin) {
        x = translationLimits.xMin
    }
    if (x > translationLimits.xMax) {
        x = translationLimits.xMax
    }
    if (y < translationLimits.yMin) {
        y = translationLimits.yMin
    }
    if (y > translationLimits.yMax) {
        y = translationLimits.yMax
    }
    return {x, y, xMin: translationLimits.xMin, yMin: translationLimits.yMin}
}

function translationToView(translation, containerSize)
{
    return {x: (translation.x - translation.xMin) * containerSize.zoomRatio, y: (translation.y - translation.yMin) * containerSize.zoomRatio}
}

function calcFinalSizes(image, stamp, stampZoom)
{
    if (stampZoom > stamp.x) {
        const resizeRatio = stamp.x / stampZoom
        return {
            image: {x: Math.round(image.x * resizeRatio), y: Math.round(image.y * resizeRatio)},
            stamp: {x: stamp.x, y: stamp.y},
            resizeRatio: resizeRatio
        }
    } else {
        return {
            image: {x: image.x, y: image.y},
            stamp: {x: stampZoom, y: Math.round(stampZoom * stamp.y / stamp.x)},
            resizeRatio: 1,
        }
    }
}

function calcFinalOffset(translation, translationLimits, resizeRatio)
{
    return {
        x: Math.round((translation.x - translationLimits.xMin) * resizeRatio),
        y: Math.round((translation.y - translationLimits.yMin) * resizeRatio),
    }
}

const Geometry = {
    intdiv, calcContainerParams, calcContainerSize, calcStampZoomLimits, pickStampZoom,
    zoomToView, calcTranslationLimits, pickTranslation, translationToView, calcFinalSizes,
    calcFinalOffset
}

export default Geometry
