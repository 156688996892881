
const fileSuffix = "-vk"
const fileExtension = "jpg"

function after(timeout, ret)
{
    return new Promise((resolve) => {setTimeout(() => resolve(ret), timeout)})
}


async function createImageFrom(src)
{
    return new Promise((resolve) => {
        var image = new Image()
        image.src = src
        image.onload = () => resolve(image)
    })
}

function createFinalFilename(filename)
{
    var matches = filename.match(/^(.*)\.[^\.]+$/)
    if (matches) {
        filename = matches[1]
    }
    if (filename.toLowerCase() == filename) {
        filename += fileSuffix.toLowerCase() + "." + fileExtension.toLowerCase()
    } else if (filename.toUpperCase() == filename) {
        filename += fileSuffix.toUpperCase() + "." + fileExtension.toUpperCase()
    } else {
        filename += fileSuffix + "." + fileExtension
    }
    return filename
}

async function invokeJob(job)
{
    const canvas = document.createElement('canvas')
    canvas.width = job.imageFinalSize.x
    canvas.height = job.imageFinalSize.y
    const context = canvas.getContext("2d")
    
    const [image, stamp] = await Promise.all([createImageFrom(job.image), createImageFrom(job.stamp)])
    context.fillStyle = '#fff';
    context.fillRect(0, 0, job.imageFinalSize.x, job.imageFinalSize.y);
    context.drawImage(image, 0, 0, job.imageOrigSize.x, job.imageOrigSize.y, 0, 0, job.imageFinalSize.x, job.imageFinalSize.y)
    context.drawImage(stamp, 0, 0, job.stampOrigSize.x, job.stampOrigSize.y, job.stampFinalOffset.x, job.stampFinalOffset.y, job.stampFinalSize.x, job.stampFinalSize.y)
    return {src: canvas.toDataURL("image/jpeg", 0.95), originalFilename: job.originalFilename, finalFilename: createFinalFilename(job.originalFilename)}
}

export default invokeJob
