const handleFileChange = (setFile) => (event) => {
    if (event.target.files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.addEventListener("load", function () {
            setFile && setFile({src: this.result, filename: event.target.files[0].name})
        });
    }
}

function SelectImage(props)
{
    return <div className="select-image">
        <input type="file" onChange={handleFileChange(props.setFile)} accept="image/png, image/gif, image/jpeg" />
    </div>
}

export default SelectImage;

