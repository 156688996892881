import './App.css';

import Editor from './Editor.js'

function App() {
  return (
    <Editor />
  );
}

export default App;
