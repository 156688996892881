import {useState, useEffect} from "react"

function Wait(props)
{
    const [dots, setDots] = useState("")
    const message = props.message || "Prosím čekejte"
    var newDots = dots
    useEffect(() => {
        var interval = setInterval(() => {
            newDots += "."
            if (newDots.length > 3) {
                newDots = ""
            }
            setDots(newDots)
        }, 500)
        return () => {
            if (interval !== null) {
                clearInterval(interval)
            }
        };
    }, []);
    return <div className="wait">{message}<span>{dots}</span></div>
}

export default Wait;



