import {useState} from "react"
import stamp1 from './stamps/fotoramecek1.png';
import stamp2 from './stamps/fotoramecek2.png';

const stamps = [stamp1, stamp2]

function SelectStamp(props)
{
    const [selectedIndex, setSelectedIndex] = useState(null)

    const selectIndex = (index) => () => {
        setSelectedIndex(index)
        props.setStamp && props.setStamp(stamps[index])
    }

    if (selectedIndex === null) {
        setTimeout(() => selectIndex(0)(), 0)
    }

    return <div className="select-stamp">
            <div className="selector">{stamps.map(
                (stamp, index) => <img key={index} className={(selectedIndex == index) ? 'selected' : 'not-selected'} src={stamp} onClick={selectIndex(index)} />
            )}</div>
        </div>
}

export default SelectStamp;

